<template>
  <common :title="title">
    <div class="idCard" @click="getCardNumber">
      <img src="./img/idCard.png" alt="" />
    </div>
  </common>
</template>

<script>
import { base64toFile } from "@/utils/utils.js";
import { uploadUrl, aoiLogin, aoiRealnameAudit } from "./api.js";
import common from "../components/common.vue";
import storage from "../utils/storage";
import { mapState } from "vuex";
export default {
  name: "humanFaceRegister",
  components: {
    common,
  },
  props: {},
  data() {
    return {
      title: "正在读取身份证...",
      signImageUrl: "",
      userInfo: {
        name: "",
        sex: null,
        idNum: "",
        avatar: "",
      },
      isRegister: "",
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    const { isRegister } = this.$route.query;
    if (![null, undefined, ""].includes(isRegister)) {
      this.isRegister = isRegister;
      this.title =
        isRegister == 0
          ? "正在读取身份证..."
          : isRegister == 1
          ? "正在读取身份证..."
          : "正在读取身份证...";
    }
  },
  mounted() {
    window.sys.getCardNumber();
    window.onIdCardRecognized = this.onIdCardRecognized;
  },
  methods: {
    //实名认证
    aoiRealnameAudit(
      realName,
      idCardNum,
      idCardPhotoFront,
      idCardPhotoBack,
      sex
    ) {
      this.$axios
        .post(aoiRealnameAudit, {
          realName,
          idCardType: "居民身份证",
          idCardNum,
          idCardPhotoFront,
          idCardPhotoBack,
          sex,
          userId: this.userId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$toast(res.msg);
            this.$router.back();
          }
        });
    },
    //获取身份证
    getCardNumber() {
      window.sys.getCardNumber();
    },
    onIdCardRecognized(name, sex, idNum, imageBase64) {
      console.log("name----->" + name);
      this.userInfo.name = name;
      this.userInfo.sex = sex;
      this.userInfo.idNum = idNum;
      if (this.isRegister == 0) {
        this.uploadImg(base64toFile(imageBase64));
        this.$router.push({
          name: "IdentitySubmission",
          query: {
            isRegister: this.isRegister,
            userInfo: JSON.stringify(this.userInfo),
          },
        });
      } else if (this.isRegister == 1) {
        this.aoiLogin(idNum);
      } else {
        this.uploadImg(base64toFile(imageBase64));
        this.aoiRealnameAudit(
          name,
          idNum,
          this.userInfo.avatar,
          this.userInfo.avatar,
          sex
        );
      }
    },
    //身份证登录
    aoiLogin(idCardNum) {
      this.$axios
        .post(aoiLogin, {
          idCardNum,
          registerType: 1,
          deviceNo: storage.get('deviceNo') || ''
        })
        .then((res) => {
          if (res.code == 200) {
            const { data } = res;
            storage.set("systemUserInfo", data);
            this.$store.commit("setUserId", data.userId);
            this.$store.commit("setHouseId", data.houseId);
            this.$store.commit("setTenantId", data.tenantId);
            this.$store.commit("setCommunityId", data.communityId);
            this.$router.push({
              name: "guidePage",
            });
          }
        });
    },
    // 转为图片
    async uploadImg(file) {
      var formData = new FormData();
      formData.append("file", file);
      let res = await this.$axios.post(uploadUrl, formData);
      if (res.code == 200) {
        this.userInfo.avatar = res.data.link;
      }
    },
  },
};
</script>

<style scoped lang="less">
.idCard {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 261px;
    height: 379px;
  }
}
</style>
